import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReactPlayer from "react-player";
import MainView from "./MainView/MainView";

import useGoogleSheets from "use-google-sheets";
import styled from "styled-components";
import MainMenu from "./MainMenu/MainMenu";
import { device } from "./device";
import { useState } from "react";
import Home from "./Home";

const MainViewContainer = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  /* grid-template-columns: 1fr; */
  /* grid-template-rows: 2fr 1fr; */
  justify-content: center;
  @media only screen and ${device.tablet} {
    /* grid-template-columns: 1fr 1fr 300px; */
    /* grid-template-rows: 20px 1fr 200px; */
    /* background-color: pink; */
  }
`;

const MessageContainer = styled.div`
  margin: auto;
  grid-area: 1/2/3/3;
`;

function App() {
  const { data, loading, error, refetch } = useGoogleSheets({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    sheetId: process.env.REACT_APP_GOOGLE_SHEETS_ID,
  });

  const [audioIsReady, setAudioIsReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <Router>
      {/* <ReactPlayer
        controls={false}
        loop={true}
        style={{ position: "fixed" }}
        url={process.env.PUBLIC_URL + "./bg.mp3"}
        playing={isPlaying}
        onReady={() => {
          setAudioIsReady(true);
        }}
      /> */}
      <MainViewContainer>
        {loading && (
          <MessageContainer>
            <p className="tac">Loading...</p>
          </MessageContainer>
        )}
        {error && (
          <MessageContainer>
            <p className="tac">Error...</p>
          </MessageContainer>
        )}
        {!loading && !error && (
          <Routes>
            <Route path="/" element={<Home />} sty />
            <Route
              path="/privacy"
              element={
                <div
                  className="tac"
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    color: "black",
                  }}
                >
                  <p style={{ margin: "1rem" }}>Privacy Policy</p>
                  <p style={{ margin: "1rem" }}>
                    John Powell-Jones & John Howes built the Web Wide World app
                    as a Free app. This SERVICE is provided by John Powell-Jones
                    & John Howes at no cost and is intended for use as is. This
                    page is used to inform visitors regarding my policies with
                    the collection, use, and disclosure of Personal Information
                    if anyone decided to use my Service.
                  </p>
                  <p style={{ margin: "1rem" }}>
                    Web Wide World does not collect any data
                  </p>
                </div>
              }
            ></Route>
            <Route
              path="/v1"
              element={<MainMenu setIsPlaying={setIsPlaying} />}
            ></Route>
            <Route
              path="/:id"
              element={<MainView data={data[0].data} />}
            ></Route>
          </Routes>
        )}
      </MainViewContainer>
    </Router>
  );
}

export default App;
